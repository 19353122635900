import { AxiosError } from 'axios';
import {
  postResponse,
  ResponsePostRequestV1,
  ActionTypeKeysEnum,
  ResponsePostDelayItemsV1,
  Env,
  TransmissionMethodKeysEnumForSirV1,
  isRetryableError
} from '@cimpress-technology/supplier-integrations-client';
import { ItemsActions } from '../storeActions';
import {
  ControlledTableState,
  Filters,
  GetItemsErrorAction,
  GetItemsSuccessAction,
  ToggleRowSelectionAction,
  ToggleAllSelectionAction,
  TableSorting,
  GetPlatformItemsDoneAction,
  PlatformItems,
  PlatformItem,
  PlatformItemsLinks
} from './types';
import { AppState } from '../store';
import { SearchParameters,
  OrderItemWithStatusAndOrderInfo,
  searchForItems,
  ItemSearchResults, getItemsWithStatusAndOrderInfo
} from '../../clients/foma/itemsClient';
import { DismissibleErrorDetails, ErrorDetails } from '../commonTypes';
import {
  performPostOrderItemRejectionActions,
  markAsDelayed
} from '../../clients/foma/notificationActionClient';
import { REJECTION_EXPECTATIONS_CODE } from '../../utils/rejectionReasons';
import { isProd, rejectionUnmetExpectationUrl } from '../../utils/environmentProvider';

import { i18n } from '../../locales/i18n';
import { sortingKeysMap } from '../../clients/foma/itemSearchRequestModel';

import {
  PlatformClient
} from '../../clients/platformClient/platformClient';
import { getClaim } from '../../clients/claims/claimsClient';
import { getPrintJob } from '../../clients/printjobs/printjobClient';
import { PlatformOrderItem, PlatformSearchOrderItem } from '../../clients/platformClient/platformItemsModel';
import { SentryWrapper } from '@cimpress-technology/react-reporting-redux';
import ReactGA from 'react-ga';
import { pathnameToCategory } from '../../trackingLayer/utils';
import { sortItemsTableLocally, keysSupportingLocalSort } from '../../utils/localSorting';
import { getDeliveryCountryDetails } from '../../utils/deliveryCountry';
import { getErrorDetailsFromAxiosError } from '../../clients/apiClient';

const getItemsSuccess = (values: ItemSearchResults): GetItemsSuccessAction => ({
  type: ItemsActions.GET_ITEMS_SUCCESS,
  data: values
});

const getItemsError = (error: ErrorDetails): GetItemsErrorAction => ({
  type: ItemsActions.GET_ITEMS_ERROR,
  error
});

const getPlatformItemsSuccess = (platformItemsByItemsId: PlatformItems): GetPlatformItemsDoneAction => ({
  type: ItemsActions.GET_PLATFORM_ITEMS_DONE,
  platformItemsByItemsId
});

const resolvePrintJob = async (accessToken, printJobLink, errors) => {
  if (printJobLink) {
    try {
      return await getPrintJob(accessToken, printJobLink);
    } catch (pjE: any) {
      if (pjE.error) {
        errors.push(pjE.error);
      }
    }
  }
  return undefined;
};

export const getPlatformItemsRequest = () => async (dispatch, getState) => {
  const state = getState() as AppState;
  const platformClient = new PlatformClient();
  if (!state.items.table.data?.items) {
    return;
  }
  dispatch({ type: ItemsActions.GET_PLATFORM_ITEMS_REQUEST });
  const accessToken = state.auth.accessToken;
  const responsesErrors: ErrorDetails[] = [];

  // fetch items through search
  const itemIds = state.items.table.data.items.map(it => it.itemId);
  let platformItemsMap = {} as (Record<string, PlatformSearchOrderItem>|undefined);
  try {
    platformItemsMap = await platformClient.searchPlatformItemsByIds(accessToken, itemIds);
  } catch (e) {
    // Do not report this as it has been already reported
    // SentryWrapper.reportError(e);
    // for now it's ok to ignore this.
  }

  // fetch each item separately
  const platformItems = await Promise.all(state.items.table.data.items.map(async item => {
    try {
      // If we already have the data from the OM's search...
      if (platformItemsMap && platformItemsMap[item.itemId]) {
        const links: PlatformItemsLinks = {};
        const platformSearchItem = (platformItemsMap[item.itemId]) as (PlatformSearchOrderItem | undefined);
        const res = {
          itemId: platformSearchItem?.shortItemId,
          quantity: platformSearchItem?.quantity,
          merchantId: platformSearchItem?.merchantId,
          platformItemId: platformSearchItem?.itemId,
          statuses: platformSearchItem?.statuses,
          links
        } as PlatformItem;

        // Add CLAIM ID
        if (platformSearchItem?.linkedData?.claims && platformSearchItem?.linkedData.claims.results.length > 0) {
          // TBD: Handle multiple claims ?!@
          links.claims = {
            href: 'do-we-need?',
            name: platformSearchItem?.linkedData.claims.results[0].id
          };
        }

        // Add REORDER CLAIM ID
        if (platformSearchItem?.linkedData?.reorderClaim && platformSearchItem?.linkedData.reorderClaim) {
          // TBD: Handle multiple claims ?!@
          links.reorderClaim = {
            href: 'do-we-need?',
            name: platformSearchItem?.linkedData.reorderClaim.id
          };
        }

        res.printJob = await resolvePrintJob(accessToken, platformSearchItem?._links?.['https://relations.cimpress.io/pool/print-job']?.href, responsesErrors);

        return res;
      }

      // ELSE - go fetch from Item Service

      const platformItem: PlatformOrderItem|undefined = item.links['platform-item']?.href
        ? (await platformClient.getPlatformItem(accessToken, item.links['platform-item']?.href))
        : (await platformClient.getItemByShortItemId(accessToken, item.itemId));

      if (!platformItem) {
        return {};
      }

      const links: PlatformItemsLinks = { claims: platformItem._links.claims };
      const res = {
        itemId: platformItem?.shortItemId,
        quantity: platformItem?.quantity,
        merchantId: platformItem?.merchantId,
        platformItemId: platformItem?.itemId,
        statuses: platformItem?.statuses,
        links
      } as PlatformItem;
      if (platformItem?._links?.reorderClaim?.href) {
        try {
          const orderClaim = await getClaim(accessToken, platformItem?._links?.reorderClaim.href);
          links.reorderClaim = {
            href: platformItem._links.reorderClaim.href,
            name: orderClaim?.id ?? ''
          };
        } catch (claimE: any) {
          if (claimE.error) {
            responsesErrors.push(claimE.error);
          }
        }
      }

      res.printJob = await resolvePrintJob(accessToken, platformItem?._links?.['https://relations.cimpress.io/pool/print-job']?.href, responsesErrors);
      return res;
    } catch (e: any) {
      if (e.error) {
        responsesErrors.push(e.error);
      }
      return {};
    }
  })) as PlatformItem[];

  if (responsesErrors.length) {
    SentryWrapper.reportError(responsesErrors);
    dispatch(showAlert(responsesErrors.map(responseError => ({ ...responseError, onDismissed: (error => dispatch(dismissAlert(error))) }))));
  }

  dispatch(getPlatformItemsSuccess(platformItems.reduce((acc: PlatformItems, i) => (
    i.itemId
      ? {
        ...acc, [i.itemId]: {
          itemId: i.itemId,
          quantity: i.quantity,
          merchantId: i.merchantId,
          platformItemId: i.platformItemId,
          links: { claims: i.links?.claims, reorderClaim: i.links?.reorderClaim },
          statuses: { fulfillmentDelayed: i.statuses?.fulfillmentDelayed, fulfillment: i.statuses?.fulfillment, fulfilled: i.statuses?.fulfilled },
          printJob: i.printJob
        }
      }
      : acc
  ), {})));
};

export const getFomaOrderItem = (itemId: string|undefined) => async (dispatch, getState) => {
  if (!itemId) {
    return;
  }

  dispatch({ type: ItemsActions.GET_FOMA_ITEM_REQUEST, itemId: itemId });
  // const responsesErrors: ErrorDetails[] = [];
  const state = getState() as AppState;
  const accessToken = state.auth.accessToken;

  try {
    const itemFetched = await getItemsWithStatusAndOrderInfo([itemId], accessToken);
    if (itemFetched.length > 0) {
      dispatch({ type: ItemsActions.GET_FOMA_ITEM_SUCCESS, itemId: itemId, data: itemFetched[0] });
    } else {
      dispatch({ type: ItemsActions.GET_FOMA_ITEM_ERROR, itemId: itemId, data: null, error: 'missing info' });
    }
  } catch (error) {
    SentryWrapper.reportError(error);
    dispatch({ type: ItemsActions.GET_FOMA_ITEM_ERROR, itemId: itemId, error: error });
  }
};

export const getItemsRequest = () => async (dispatch, getState) => {
  dispatch({ type: ItemsActions.GET_ITEMS_REQUEST });

  const state = getState() as AppState;
  const accessToken = state.auth.accessToken;

  const fulfillerIds = state.fulfillers.selectedFulfillers && state.fulfillers.selectedFulfillers?.length > 0
    ? state.fulfillers.selectedFulfillers
    : state.fulfillers.fulfillers.data?.map(fulfiller => fulfiller.fulfillerId);

  const keysNotSupportingLocalSort = state.items.table.sorted?.filter(param => !keysSupportingLocalSort.includes(param.id));

  const getApiSortingKeys = (sortingColumnsDetails: TableSorting[]): TableSorting[] => {
    return sortingColumnsDetails.map(columnDetails => {
      const defaultColumnId = columnDetails.id.split(' ').join('').toLowerCase();
      return { ...columnDetails, id: sortingKeysMap[columnDetails.id] ?? defaultColumnId };
    });
  };

  const searchParameters: SearchParameters = {
    fulfillerIds: fulfillerIds || [],
    status: state.items.filters.status,
    pageSize: state.items.table.pageSize,
    pageNumber: state.items.table.page,
    searchString: state.items.filters.fulltext,
    forecastedLate: state.items.filters.limitForecastedLate,
    noExpectedShipTime: state.items.filters.filterItemsWithNoPlan,
    productCategories: state.items.filters.productCategories,
    deliveryOptionIds: state.items.filters.deliveryOptionIds,
    sort: getApiSortingKeys(keysNotSupportingLocalSort),
    expectedShipTimeFrom: state.items.filters.expectedShipTimeFrom,
    expectedShipTimeTo: state.items.filters.expectedShipTimeTo
  };

  try {
    const extendedOrderItems = await searchForItems(searchParameters, accessToken);
    if (extendedOrderItems) {
      ReactGA.event({
        category: pathnameToCategory(),
        action: 'search.results',
        label: window.location.search,
        value: extendedOrderItems.totalCount
      });

      extendedOrderItems.items = await getDeliveryCountryDetails(extendedOrderItems.items, accessToken);

      if (keysSupportingLocalSort.includes(state.items.table.sorted[0].id)) {
        extendedOrderItems.items = sortItemsTableLocally(extendedOrderItems.items || [], state.items.table.sorted[0].id, state.items.table.sorted[0].desc);
      }
    
      dispatch(getItemsSuccess(extendedOrderItems));
      dispatch(getPlatformItemsRequest());
    } else {
      // call was cancelled.
      // do nothing
    }
  } catch (getItemsResponse: any) {
    if (getItemsResponse.error) {
      // This should be reported in apiRequest.
      // SentryWrapper.reportError(getItemsResponse.error);
      if (getItemsResponse.status === 403) {
        dispatch(getItemsError({
          message: i18n.t('errorMessages.insufficientFulfillerPermissions'),
          severity: 'warning'
        }));
      } else {
        dispatch(getItemsError({
          ...getItemsResponse.error,
          message: i18n.t('errorMessages.failedToRetrieveData')
        }));
      }
    }
  }
};

export const setItemsFilters = (filters: Filters) => dispatch => {
  dispatch({ type: ItemsActions.SET_ITEMS_FILTERS, filters: filters });
};

export const setItemsTableState = (controlledTableState: ControlledTableState) => dispatch => {
  dispatch({ type: ItemsActions.SET_ITEMS_TABLE_STATE, table: controlledTableState });
};

export const toggleRowSelection = (selection: string): ToggleRowSelectionAction => ({
  type: ItemsActions.TOGGLE_ROW_SELECTION,
  selection
});

export const acceptOrderRequest = (selectedItems: OrderItemWithStatusAndOrderInfo[], refreshItemListData = true) => async (dispatch, getState) => {
  dispatch({ type: ItemsActions.BULK_ACCEPT_REQUEST });
  const state = getState() as AppState;
  const accessToken = state.auth.accessToken;
  const responsesErrors: ErrorDetails[] = [];
  try {
    await Promise.all(selectedItems.map(async item => {
      if (!item || !!item?.status?.statusDetails.rejected) {
        return;
      }

      const siPostRequestPayload: ResponsePostRequestV1 = {
        transmissionMethodKey: TransmissionMethodKeysEnumForSirV1.Pom,
        actionTypeKey: ActionTypeKeysEnum.AcceptOrderRequest,
        actionTypeConfiguration: {
          [ActionTypeKeysEnum.AcceptOrderRequest]: {
            items: [{ itemId: item.itemId }]
          }
        }
      };

      try {
        await postResponse(accessToken, siPostRequestPayload, isProd ? Env.PRODUCTION : Env.INTEGRATION, { retryCondition: isRetryableError });
      } catch (err) {
        const errorDetails = getErrorDetailsFromAxiosError(err as AxiosError);
        if (!errorDetails) {return;} // request could be cancelled
        responsesErrors.push(errorDetails.error);
      }
    }));
  } catch (e) {
    SentryWrapper.reportError(e);
  }
  if (responsesErrors.length) {
    SentryWrapper.reportError(responsesErrors);
    dispatch({
      type: ItemsActions.SHOW_ALERT,
      errors: responsesErrors.map(responseError => ({
        ...responseError,
        onDismissed: (error => dispatch(dismissAlert(error)))
      }))
    });
  }
  dispatch({ type: ItemsActions.BULK_ACCEPT_DONE });

  if (refreshItemListData) {
    dispatch(getItemsRequest());
  } else {
    selectedItems.forEach(it => {
      dispatch(getFomaOrderItem(it.itemId));
    });
  }
};

export const rejectOrderRequest = (selectedItems: OrderItemWithStatusAndOrderInfo[], reason: string, reasonKey: string, refreshItemListData = true) => async (dispatch, getState) => {
  dispatch({ type: ItemsActions.BULK_REJECT_ORDER });
  const responsesErrors: ErrorDetails[] = [];
  const state = getState() as AppState;
  const accessToken = state.auth.accessToken;

  try {
    await Promise.all(selectedItems.map(async item => {
      if (!item || !!item?.status?.statusDetails.rejected) {
        return;
      }

      const siPostRequestPayload: ResponsePostRequestV1 = {
        transmissionMethodKey: TransmissionMethodKeysEnumForSirV1.Pom,
        actionTypeKey: ActionTypeKeysEnum.RejectItems,
        actionTypeConfiguration: {
          [ActionTypeKeysEnum.RejectItems]: {
            items: [{ itemId: item.itemId }],
            unmetExpectationsUrls: [`${rejectionUnmetExpectationUrl}${REJECTION_EXPECTATIONS_CODE[reasonKey]}`],
            unstructuredReason: reason ? `${reason}` : 'No rejection reason specified.'
          }
        }
      };

      try {
        await postResponse(accessToken, siPostRequestPayload, isProd ? Env.PRODUCTION : Env.INTEGRATION, { retryCondition: isRetryableError });
      } catch (err) {
        const errorDetails = getErrorDetailsFromAxiosError(err as AxiosError);
        if (!errorDetails) {return;} // request could be cancelled
        responsesErrors.push(errorDetails.error);
      }

      await performPostOrderItemRejectionActions(item, accessToken);
    }));
  } catch (e) {
    SentryWrapper.reportError(e);
  }

  if (responsesErrors.length) {
    SentryWrapper.reportError(responsesErrors);
    dispatch({
      type: ItemsActions.SHOW_ALERT,
      errors: responsesErrors.map(responseError => ({
        ...responseError,
        onDismissed: (error => dispatch(dismissAlert(error)))
      }))
    });
  }

  dispatch({ type: ItemsActions.BULK_REJECT_ORDER_DONE });
  if (refreshItemListData) {
    dispatch(getItemsRequest());
  } else {
    selectedItems.forEach(it => {
      dispatch(getFomaOrderItem(it.itemId));
    });
  }
};

export function showAlert(errors: DismissibleErrorDetails[]) {
  return {
    type: ItemsActions.SHOW_ALERT,
    errors
  };
}

export function dismissAlert(error: ErrorDetails) {
  return {
    type: ItemsActions.DISMISS_ALERT,
    error
  };
}

export const setToProduction = (selectedItems: OrderItemWithStatusAndOrderInfo[], refreshItemListData = true) => async (dispatch, getState) => {
  dispatch({ type: ItemsActions.BULK_SET_TO_PRODUCTION });
  const state = getState() as AppState;
  const accessToken = state.auth.accessToken;
  const errors: DismissibleErrorDetails[] = [];
    
  try {
    await Promise.all(selectedItems.map(async item => {
      if (!item || !!item?.status?.statusDetails.rejected) {
        return;
      }
      const quantity = item.orderedQuantity - (item.status.statusDetails?.production?.quantity || 0) - (item.status.statusDetails?.shipped?.quantity || 0);

      const siPostRequestPayload: ResponsePostRequestV1 = {
        transmissionMethodKey: TransmissionMethodKeysEnumForSirV1.Pom,
        actionTypeKey: ActionTypeKeysEnum.SetItemsToProduction,
        actionTypeConfiguration: {
          [ActionTypeKeysEnum.SetItemsToProduction]: {
            items: [{ itemId: item.itemId, quantity }]
          }
        }
      };

      try {
        await postResponse(accessToken, siPostRequestPayload, isProd ? Env.PRODUCTION : Env.INTEGRATION, { retryCondition: isRetryableError });
      } catch (err) {
        const errorDetails = getErrorDetailsFromAxiosError(err as AxiosError);
        if (!errorDetails) {return;} // request could be cancelled
        errors.push({
          ...errorDetails.error,
          onDismissed: (error => dispatch(dismissAlert(error)))
        });
      }
    }));
  } catch (e) {
    SentryWrapper.reportError(e);
  }

  if (errors.length !== 0) {
    SentryWrapper.reportError(errors);
    dispatch(showAlert(errors));
  }

  dispatch({ type: ItemsActions.BULK_SET_TO_PRODUCTION_DONE });
  if (refreshItemListData) {
    dispatch(getItemsRequest());
  } else {
    selectedItems.forEach(it => {
      dispatch(getFomaOrderItem(it.itemId));
    });
  }
};

export const toggleAllSelection = (): ToggleAllSelectionAction => ({ type: ItemsActions.TOGGLE_ALL_SELECTION });

export const notifyDelay = (selectedItems: OrderItemWithStatusAndOrderInfo[], processDeviation: Omit<ResponsePostDelayItemsV1['delayItems'], 'items'>, refreshItemListData = true) => async (dispatch, getState) => {
  dispatch({ type: ItemsActions.BULK_NOTIFY_DELAY });
  const responsesErrors: ErrorDetails[] = [];
  const state = getState() as AppState;
  const accessToken = state.auth.accessToken;

  try {
    await Promise.all(selectedItems
      .filter(item => item && !item?.status?.statusDetails.rejected)
      .map(async item => {
        if (!item) {
          return;
        }
        const potentialError = await markAsDelayed(item, accessToken, processDeviation);
        if (potentialError) {
          responsesErrors.push(potentialError);
        } else {
          const platformItem = state.items.table.platformItemsByItemsId[item.itemId];
          if (!platformItem) { return; }
          const updatedPlatformItem = Object.assign({}, platformItem);
          updatedPlatformItem.statuses = { ...updatedPlatformItem.statuses, fulfillmentDelayed: {
            expectedCloseDate: processDeviation.expectedShipDate as string,
            detail: processDeviation.unstructuredReason,
            state: 'current',
            updatedDate: new Date().toISOString()
          } };

          dispatch({ type: ItemsActions.NOTIFY_DELAY_DONE, itemId: item.itemId, data: updatedPlatformItem });
        }
      }));
  } catch (e) {
    SentryWrapper.reportError(e);
  }

  if (responsesErrors.length) {
    SentryWrapper.reportError(responsesErrors);
    dispatch({
      type: ItemsActions.SHOW_ALERT,
      errors: responsesErrors.map(responseError => ({
        ...responseError,
        onDismissed: (error => dispatch(dismissAlert(error)))
      }))
    });
  }

  dispatch({ type: ItemsActions.BULK_NOTIFY_DELAY_DONE });
  if (refreshItemListData) {
    dispatch(getItemsRequest());
  } else {
    selectedItems.forEach(it => {
      dispatch(getFomaOrderItem(it.itemId));
    });
  }
};
